<template>
    <div class="pinned-box" style="/*display: none;*/">
        <!--<div class="pinned-header"></div>-->
        <div class="pinned-header--title"><i class="fas fa-exclamation-circle"></i>&nbsp;upcoming risk events today <span class="upcoming-event-number">(0)</span></div>
        <div class="pinned-news">
            <div class="pinned-news--item" id="">
                <div class="pinned-news--item-expandable" @click="openNews">
                    <div class="pinned-news--item__end">18:00</div>
                    <div class="pinned-news--item__countdown">2hr 25min</div>
                    <div class="pinned-news--item__headline">Risk Event Report: FOMC at 18:00</div>
                    <div class="pinned-news--item__plus">
                        <font-awesome-icon v-if="!newsIsOpen" :icon="['fas', 'comment']" />
                        <font-awesome-icon v-else :icon="['fas', 'minus-circle']" />
                    </div>
                </div>
                <div class="pinned-news--item__news" v-if="newsIsOpen">
                    The Federal Open Market Committee, a committee within the Federal Reserve System, is charged under United States law with overseeing the nation's open market operations. This Federal Reserve committee makes key decisions about interest rates and the growth of the United States money supply
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newsIsOpen: false
        }
    }, 
    methods: {
        openNews() {
            this.newsIsOpen = !this.newsIsOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
    .pinned-box {
        grid-column: 1 / -1;
        margin: 15px 5px 20px 5px;
        border: 2px solid $color-20;
    }
</style>